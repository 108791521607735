import React, { useEffect, useState } from 'react'
import {useSelector} from "react-redux"
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { request } from '../../util/fetchAPI'
import { Link } from "react-router-dom"
import "./AddProducts.css"

export default function AddProducts() {

  const [state, setState] = useState()
  const [photo, setPhoto] = useState();
  const [productCatData, setProductCatData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const {token} = useSelector((state)=>state.auth)


  const handleState = (e) => {
    setState((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  const successNotifier = (data) => {

    toast.success(data, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });

  }

  const errorNotifier = (data) => {

    toast.error(data, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });

  }

  //Fetch Products Catagories
  const fetechProductCat = async () => {
    try {
      const productCatData = await request("/product/getAllProductCat", "GET", {  authorization:`Bearer ${token}`})
      setProductCatData(productCatData)
    } catch (error) {
      console.log(error)
    }
  }

  //Register Products Catagories
  const handleRegister = async (e) => {
    e.preventDefault()
    try {
      setIsLoading(true)
      let filename = null
      const formData = new FormData();
      if (photo) {
          filename = crypto.randomUUID() + photo.name;
          formData.append("filename", filename);
          formData.append("image", photo);
          await fetch(`https://api.ishaqkhantruckart.com/upload/image`, {
              headers: {
                authorization: `Bearer ${token}`
              },
              method: "POST",
              body: formData
            });
        }

      const productCatData = await request("/product/addNewProduct", "POST", {
        authorization:`Bearer ${token}`,
        "Content-Type": "application/json"
      }, { ...state, productImg:filename })

      if (productCatData.status === 200) {

        successNotifier(productCatData.data)
      } else {
        errorNotifier(productCatData.data)
      }

      setIsLoading(false)
      fetechProductCat()

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetechProductCat()
  }, [])

  return (
    <div>
      <ToastContainer />
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb m-4">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Add Product</li>
          </ol>
        </nav>
        <div className='ProductCat' >
          <div className='ProductCatForm'>
            <fieldset className="border-2 border-black p-1">
              <legend className="font-bold text-blue-700 ">Product Information</legend>
              <form onSubmit={handleRegister}>

                <div className="row mobileForm">
                  <div className="col">
                    <label htmlFor="" className=' block mb-2 labels'>Product Category</label>
                    <select type="text" name='productCat' className="form-control inputs" placeholder="First name" onChange={handleState} required>
                      <option hidden>Chose...</option>
                      {productCatData.map((data, index) => (
                        <option key={data._id} value={data._id}>{++index} - {data.proCatDec}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col">
                    <label htmlFor="" className=' block mb-2 labels'>Product Name</label>
                    <input type="text" name='productName' className="form-control inputs" placeholder="Product Name" onChange={handleState} required />
                  </div>

                </div>

                <div className="row mobileForm">
                  <div className="col">
                    <label htmlFor="" className=' block mb-2 labels'>Manufectured By</label>
                    <input type="text" name='manufBy' className="form-control inputs" placeholder="Manufectured By" onChange={handleState} required />
                  </div>
                  <div className="col">
                    <label htmlFor="" className=' block mb-2 labels'>Description</label>
                    <input type="text" name='productDec' className="form-control inputs" placeholder="Product Description" onChange={handleState} required />
                  </div>
                </div>

                <div className="row mobileForm">
                  <div className="col">
                    <label htmlFor="" className=' block mb-2 labels'>Product Detail</label>
                    <input type="text" name='productDetail' className="form-control inputs" placeholder="Product Detail" onChange={handleState} required />
                  </div>
                  <div className="col">
                    <label htmlFor="" className=' block mb-2 labels'>Sizes</label>
                    <input type="text" name='productSize' className="form-control inputs" placeholder="Enter Size" onChange={handleState} required />
                  </div>
                </div>

                <div className="row mobileForm">
                  <div className="col">
                    <label htmlFor="" className=' block mb-2 labels'>Material</label>
                    <input type="text" name='productMaterial' className="form-control inputs" placeholder="Product Material" onChange={handleState} required />
                  </div>
                  <div className="col">
                    <label htmlFor="" className=' block mb-2 labels'>Unit</label>
                    <input type="text" name='productUnit' className="form-control inputs" placeholder="Enter Unit (No, Kg, set etc)" onChange={handleState} required />
                  </div>
                </div>

                <div className="row mobileForm">
                  <div className="col">
                    <label htmlFor="" className=' block mb-2 labels'>Promotion Price</label>
                    <input type="Number" name='promoPrice' min="0" className="form-control inputs" placeholder="Discounted Price" onChange={handleState} required />
                  </div>
                  <div className="col">
                    <label htmlFor="" className=' block mb-2 labels'>Price</label>
                    <input type="Number" name='productPrice' min='1' className="form-control inputs" placeholder="Product Price" onChange={handleState} required />
                  </div>
                </div>

                <div className="row mobileForm">
                  <div className="col">
                    <label htmlFor="" className=' block mb-2 labels'>Picture # 1</label>
                    <input className="form-control inputs" name="photo"
                      type="file" id="photo" onChange={(e) => setPhoto(e.target.files[0])} required />
                  </div>

                </div>

                <div>
                  <button className='button-Primary btn btn-primary'>
                    Submit
                    {isLoading &&
                      <div className="spinner-border" style={{ height: "20px", width: "20px", marginLeft: "5px" }} role="status">
                        <span className="sr-only"></span>
                      </div>}
                  </button>
                </div>
              </form>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  )
}
