import React, { useEffect, useState } from 'react'
import { ToastContainer, toast, Bounce } from 'react-toastify';
import {useSelector} from "react-redux"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { request } from '../../util/fetchAPI'
import { Link } from "react-router-dom"
import { MdDelete, MdEdit } from "react-icons/md";
import 'react-toastify/dist/ReactToastify.css';
import "./ProductCat.css"

export default function ProductCat() {

  const [state, setState] = useState()
  const [productCatData, setProductCatData] = useState([])
  const [deletingId, setDeletingId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoading2, setIsLoading2] = useState(false)
  const {token} = useSelector((state)=>state.auth)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleState = (e) => {
    setState((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

const successNotifier = (data)=>{

  toast.success(data, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    transition: Bounce,
    });

}

const warnNotifier = (data)=>{

  toast.warn(data, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    transition: Bounce,
    });
}

const errorNotifier = (data)=>{

  toast.error(data, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    transition: Bounce,
    });

}

  //Fetch Products Catagories
  const fetechProductCat = async () => {
    try {
      const productCatData = await request("/product/getAllProductCat", "GET", {authorization:`Bearer ${token}`})
      setProductCatData(productCatData)
    } catch (error) {
      console.log(error)
    }
  }


  //Register Products Catagories
  const handleRegister = async (e) => {
    e.preventDefault()
    try {
      setIsLoading(true)
      const productCatData = await request("/product/addProductCat", "POST", {
        authorization:`Bearer ${token}`,
        "Content-Type": "application/json"
      }, { ...state })

      if(productCatData.status === 200){
        successNotifier(productCatData.data)
      }else{
        errorNotifier(productCatData.data)
      }

      setIsLoading(false)
      fetechProductCat()

    } catch (error) {
      console.log(error)
    }
  }

  //delete Products Catagories
  const handleDelete = async (id) => {
    try {
      setIsLoading2(true)
      const productCatData = await request(`/product/deleteProductCat/${id}`, "DELETE", {
        authorization:`Bearer ${token}`,
        "Content-Type": "application/json"
      })
      setIsLoading2(false)
      handleClose();
      fetechProductCat()
      if(productCatData.status === 200){
        warnNotifier(productCatData.data)
      }else{
        errorNotifier(productCatData.data)
      }


    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetechProductCat()
  }, [])

  return (
    <div>
      <ToastContainer />
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb m-4">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Product Category</li>
        </ol>
      </nav>
      <div className='ProductCat' >
        <div className='ProductCatForm'>
          <fieldset className="border-2 border-black p-1">
            <legend className="font-bold text-blue-700 ">Product Category</legend>
            <form onSubmit={handleRegister}>

              <div className="row mobileForm">
                <div className="col">
                  <label htmlFor="" className=' block mb-2 labels'>Category Description</label>
                  <input type="text" name='proCatDec' className="form-control inputs" placeholder="Category Description" onChange={handleState} required/>
                </div>
                <div className="col">
                  <label htmlFor="" className=' block mb-2 labels'>Remarks</label>
                  <input type="text" name='remarks' className="form-control inputs" placeholder="Remarks" onChange={handleState} />
                </div>
              </div>

              <div>
                <button className='button-Primary btn btn-primary'>
                  Submit
                  {isLoading &&
                    <div className="spinner-border" style={{ height: "20px", width: "20px", marginLeft: "5px" }} role="status">
                      <span className="sr-only"></span>
                    </div>}
                </button>
              </div>
            </form>
          </fieldset>
        </div>
      </div>

      <div className='container tableContainer mt-5'>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Category</th>
              <th>Remarks</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {productCatData.length === 0
              ?
              <tr>
                <td colSpan="4">No Data</td>
              </tr>
              :
              productCatData.map((data, index) => (
                <tr key={data._id}>
                  <td>{++index}</td>
                  <td>{data.proCatDec}</td>
                  <td>{data.remarks}</td>
                  <td>{data.isActive === true ? "Active" : "In-Active"}</td>
                  <td className='w-10'>
                  <div className='flex'>
                    <MdDelete className='DeleteIcon' onClick={() => { handleShow(); setDeletingId(data._id) }} />
                      <MdEdit className='EditIcon' />
                    </div>
                    </td>

                </tr>

              ))}
          </tbody>

        </table>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Deletion Warring!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do You Want To Delete This Data</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => { handleDelete(deletingId) }}>
            Delete
            {isLoading2 &&
              <div className="spinner-border" style={{ height: "20px", width: "20px", marginLeft: "5px" }} role="status">
                <span className="sr-only"></span>
              </div>}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

  )
}
