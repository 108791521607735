import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navbar/Navbar'
import Contents from './components/Contents/Contents'
import "./App.css"
import Login from './components/Login/Login';


export default function App() {

  const {user} = useSelector((state)=>state.auth)

  return (
    <div className='App'>
        <Routes>
        <Route path='/Login' element={<Login/>} />
        </Routes>

          {!user
          ?
          <Navigate to={!user ? "/Login" : "/"}/>
          :
          <>
          <Navbar/>
          <Contents/>
          </>}



    </div>
  )
}
