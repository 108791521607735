import React from 'react'
import {useSelector} from "react-redux"
import { useState } from 'react'
import { useEffect } from 'react'
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom"
import { request } from '../../util/fetchAPI'
import { FaRegEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";
import { GrStatusGood } from "react-icons/gr";
import "./Order.css"


export default function Order() {

  const [orderData, setOrderData] = useState([])
  const {token} = useSelector((state)=>state.auth)
  const navigate = useNavigate()


  const successNotifier = (data) => {

    toast.success(data, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });

  }

  const errorNotifier = (data) => {

    toast.error(data, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });

  }

  const orderStateHandler = async (id,state) => {
    try {

        const data = await request(`/order/upateOrderState/${id}`, "POST",{     authorization:`Bearer ${token}`,
            "Content-Type":"application/json"
        }, {state})
        console.log(data)
        if(data.status === 200){
            successNotifier(data.data)
            fetchAllOrder()
        }else{
            errorNotifier(data.data)
        }


    } catch (error) {
        console.log(error)
    }
}

  const fetchAllOrder = async()=>{
    try {

      const data = await request("/order/getPendingOrder", "GET",{
        authorization:`Bearer ${token}`,
      })
      setOrderData(data)

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    fetchAllOrder()
  },[])
  return (
    <div>
      <ToastContainer />
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb m-4">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Orders</li>
        </ol>
      </nav>
      <div className='container orderTableContainer'>
        <table className=''>
          <thead>
            <tr className=''>
              <th>#</th>
              <th>Order Id</th>
              <th>Date</th>
              <th>Amount</th>
              <th>is-Order</th>
              <th>is-Confirmed</th>
              <th>is-Shipped</th>
              <th>is-Deliverd</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {orderData.map((data, index)=>(
               <tr key={data._id}>
               <td>{++index}</td>
               <td>{data.orderId}</td>
               <td>{data.createdAt.slice(0,10)}</td>
               <td>{data.paidAmount}.00</td>
               <td><span className='flex justify-center gap-0'>
              {data.isProcessed === true ?
              <GrStatusGood className='text-3xl bg-lime-600 rounded-full text-white'/>
              :
              <RxCrossCircled className='text-3xl bg-red-600 rounded-full text-white'/>
               }
               </span></td>
               <td><span className='flex justify-center gap-0'>
              {data.isConfirmed === true ?
              <GrStatusGood className='text-3xl bg-lime-600 rounded-full text-white'/>
              :
              <RxCrossCircled className='text-3xl bg-red-600 rounded-full text-white'/>
               }
               </span></td>
               <td><span className='flex justify-center gap-0'>
              {data.isShipped === true ?
              <GrStatusGood className='text-3xl bg-lime-600 rounded-full text-white'
              />
              :
              <RxCrossCircled className='text-3xl bg-red-600 rounded-full text-white'
              onClick={()=>{orderStateHandler(data._id, "shipped")}}/>
               }
               </span></td>
               <td><span className='flex justify-center gap-0'>
              {data.isDelivered === true ?
              <GrStatusGood className='text-3xl bg-lime-600 rounded-full text-white'/>
              :
              <RxCrossCircled className='text-3xl bg-red-600 rounded-full text-white'
              onClick={()=>{orderStateHandler(data._id, "deliverd")}}/>
               }
               </span></td>

               <td >
                <div className='flex'>
                <MdDelete className='DeleteIcon' /* onClick={() => { handleShow(); setDeletingId(data._id) }} */ />
                <FaRegEye className='EditIcon' onClick={()=>{navigate(`/orderPreview/${data._id}`)}}/>
                </div>


               </td>
             </tr>
            ))
             }
          </tbody>

        </table>
      </div>

    </div>
  )
}
