import React from 'react'
import "./Contents.css"
import AppRoutes from '../AppRoutes/AppRoutes'

export default function Contents() {
  return (
    <div className='ContentsContainer'>
        <AppRoutes/>
    </div>
  )
}
