import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from "../../redux/authSlice"
import { IoMdMenu } from "react-icons/io";
import { FaPowerOff } from "react-icons/fa";
import "./Navbar.css"

export default function Navbar() {

  const[isMenueOpen, setIsMenueOpen] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const navigator = (key)=>{
    navigate(key)
    setIsMenueOpen(false)
  }

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div className='NavContainer'>
        <div className='leftNav'><IoMdMenu className='navIcon' onClick={()=>{setIsMenueOpen((prev)=>!prev)}}/>
        </div>

          <div className={`sideNav ${isMenueOpen ? 'open' : ''}`}>
            <ul>
              <li onClick={()=>{navigator("/")}}>Home / DashBoard</li>
              <li onClick={()=>navigator("/general")}>General Setting</li>
              <li onClick={()=>navigator("/productsCat")}>Product Catagory</li>
              <li onClick={()=>navigator("/addProducts")}>Add Product</li>
              <li onClick={()=>navigator("/productsList")}>Products List</li>
              <li onClick={()=>navigator("/orders")}>Orders</li>
              <li onClick={()=>navigator("/clearOrders")}>Clear Orders</li>
              <li onClick={()=>navigator("/addPaymentMethods")}>Add Payment Methods</li>
              <li onClick={()=>navigator("/userList")}>User List</li>
              <li onClick={()=>navigator("/clients")}>Client List</li>
              <li onClick={()=>navigator("/ProductReviews")}>Custumer Reviews</li>
            </ul>
          </div>

        <div className='RightNav'>ADMIN PANEL</div>
        <div className=' absolute right-0 m-2 text-xl pr-2 text-white' onClick={()=>{handleLogout()}}><FaPowerOff /></div>
    </div>
  )
}
