import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { Link, useParams } from 'react-router-dom'
import { request } from '../../util/fetchAPI'
import "./OrderPreview.css"


export default function OrderPreview() {

    const [orderData, setOrderData] = useState({})
    const { token } = useSelector((state) => state.auth)
    const { id } = useParams()

    const successNotifier = (data) => {

        toast.success(data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
        });

    }

    const errorNotifier = (data) => {

        toast.error(data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
        });

    }



    const grandTotal = orderData?.products?.reduce((total, data) => {
        return total + data.productPrice * data.qtyOrder;
    }, 0);


    const fetchOrderInfo = async (id) => {
        try {

            const data = await request(`/order/trackOrderBy/${id}`, "GET",
                {
                    authorization: `Bearer ${token}`,
                }
            )
            setOrderData(data)

        } catch (error) {
            console.log(error)
        }
    }

    const orderStateHandler = async (id, state) => {
        try {

            const data = await request(`/order/upateOrderState/${id}`, "POST", {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }, { state })
            if (data.status === 200) {
                successNotifier(data.data)
                fetchOrderInfo(id)
            } else {
                errorNotifier(data.data)
            }


        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchOrderInfo(id)
    }, [id])
    return (
        <div>
            <ToastContainer />
            <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb m-4">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Order View</li>
                    </ol>
                </nav>
                <div className='ProductCat' >
                    <div className='ProductCatForm'>
                        <fieldset className="border-2 border-black p-1">
                            <legend className="font-bold text-blue-700 ">Order Information</legend>

                            <form >

                                <div className="row mobileForm">
                                    <div className="col">
                                        <label htmlFor="" className=' block mb-2 labels'>Order Id</label>
                                        <input type="text" value={orderData.OrderData?.orderId} className="form-control inputs" readOnly />
                                    </div>

                                    <div className="col">
                                        <label htmlFor="" className=' block mb-2 labels'>Order Date</label>
                                        <input type="text" value={orderData.OrderData?.createdAt.slice(0, 10)} className="form-control inputs" readOnly />
                                    </div>
                                </div>

                                <div className="row mobileForm">
                                    <div className="col">
                                        <label htmlFor="" className=' block mb-2 labels'>Client Name</label>
                                        <input type="text" value={orderData.OrderData?.clientId?.clientName} className="form-control inputs" readOnly />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="" className=' block mb-2 labels'>Client Email</label>
                                        <input type="text" value={orderData.OrderData?.clientId?.clientEmail} className="form-control inputs" readOnly />
                                    </div>
                                </div>

                                <div className="row mobileForm">
                                    <div className="col">
                                        <label htmlFor="" className=' block mb-2 labels'>Phone Numbers - 1</label>
                                        <input type="text" value={orderData.OrderData?.clientId?.clientContact1} className="form-control inputs" readOnly />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="" className=' block mb-2 labels'>Phone Numbers - 2</label>
                                        <input type="text" value={orderData.OrderData?.clientId?.clientContact2} className="form-control inputs" readOnly />
                                    </div>

                                </div>
                                <div className="row mobileForm">
                                    <div className="col">
                                        <label htmlFor="" className=' block mb-2 labels'>House #</label>
                                        <input type="text" value={orderData.OrderData?.clientId?.houseNo} className="form-control inputs" readOnly />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="" className=' block mb-2 labels'>Street#</label>
                                        <input type="text" value={orderData.OrderData?.clientId?.streetNo} className="form-control inputs" readOnly />
                                    </div>

                                    <div className="col">
                                        <label htmlFor="" className=' block mb-2 labels'>Landmark</label>
                                        <input type="text" value={orderData.OrderData?.clientId?.landMark} className="form-control inputs" readOnly />
                                    </div>

                                </div>
                                <div className="row mobileForm">
                                    <div className="col">
                                        <label htmlFor="" className=' block mb-2 labels'>City</label>
                                        <input type="text" value={orderData.OrderData?.clientId?.City} className="form-control inputs" readOnly />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="" className=' block mb-2 labels'>Potal Code</label>
                                        <input type="text" value={orderData.OrderData?.clientId?.postalCode} className="form-control inputs" readOnly />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="" className=' block mb-2 labels'>State</label>
                                        <input type="text" value={orderData.OrderData?.clientId?.State} className="form-control inputs" readOnly />
                                    </div>

                                </div>
                                <div className="row mobileForm">
                                    <div className="col">
                                        <label htmlFor="" className=' block mb-2 labels'>Address</label>
                                        <textarea type="text" value={orderData.OrderData?.clientId?.clientAddress} className="form-control inputs" readOnly />
                                    </div>
                                </div>

                                <div className="row mobileForm">

                                    <div className="col">
                                        <label htmlFor="" className=' block mb-2 labels'>Payment Option</label>
                                        <input type="text" value={orderData.OrderData?.paymentMethodId?.bankName + " - " + orderData.OrderData?.paymentMethodId?.accountNumber} className="form-control inputs" readOnly />
                                    </div>

                                    <div className="col">
                                        <label htmlFor="" className=' block mb-2 labels'>Payer Bank</label>
                                        <input type="text" value={orderData.OrderData?.payerBankName} className="form-control inputs" readOnly />
                                    </div>

                                </div>

                                <div className="row mobileForm">

                                    <div className="col">
                                        <label htmlFor="" className=' block mb-2 labels'>Payer Name</label>
                                        <input type="text" value={orderData.OrderData?.payerAccountTitle} className="form-control inputs" readOnly />
                                    </div>

                                    <div className="col">
                                        <label htmlFor="" className=' block mb-2 labels'>Transection Id</label>
                                        <input type="text" value={orderData.OrderData?.payerTransactionId} className="form-control inputs" readOnly />
                                    </div>

                                    <div className="col">
                                        <label htmlFor="" className=' block mb-2 labels'>Paid Amount</label>
                                        <input type="text" value={"PKR - " + orderData.OrderData?.paidAmount + ".00"} className="form-control inputs" readOnly />
                                    </div>


                                </div>
                            </form>
                        </fieldset>
                    </div>
                </div>
            </div>

            <div >
                <fieldset>
                    <legend className="font-bold pl-3 mt-3 text-blue-700 ">Order Tracking</legend>

                    <div>
                        <ul className="orderTacker">
                            <li className="list-inline-item items-list">
                                <p className={orderData?.OrderData?.isProcessed ? "py-1 px-2 rounded bg-lime-700 text-white" : "text-black"}>
                                    Ordered
                                </p>
                            </li>
                            <li className="list-inline-item items-list">
                                <p className={orderData?.OrderData?.isConfirmed ? "py-1 px-2 rounded bg-lime-700 text-white" : "text-black"}>
                                    Confirmed
                                </p>
                            </li>
                            <li className="list-inline-item items-list">
                                <p className={orderData?.OrderData?.isShipped ? "py-1 px-2 rounded bg-lime-700 text-white" : "text-black"}>
                                    On the way
                                </p>
                            </li>
                            <li className="list-inline-item items-list" style={{ marginRight: 8 }}>
                                <p className={orderData?.OrderData?.isDelivered ? "py-1 px-2 rounded bg-lime-700 text-white" : "text-black"}>
                                    Delivered
                                </p>
                            </li>
                        </ul>
                    </div>
                </fieldset>
            </div>
            <div className='container productContainer'>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Product</th>
                            <th>Size</th>
                            <th>Qty</th>
                            <th>Price</th>
                            <th>Amount</th>
                        </tr>
                    </thead>

                    <tbody>
                        {orderData?.products?.length === 0
                            ?
                            <tr>
                                <td colSpan="4">No Data</td>
                            </tr>
                            :
                            orderData?.products?.map((data, index) => (
                                <tr key={data._id}>
                                    <td>{++index}</td>
                                    <td>{data.productId?.productName}</td>
                                    <td>{data.productId?.productSize}</td>
                                    <td>{data.qtyOrder}</td>
                                    <td>{data.productPrice}.00</td>
                                    <td>{data.productPrice * data.qtyOrder}.00</td>
                                </tr>

                            ))}
                    </tbody>

                </table>
            </div>

            <div className='flex flex-col justify-center items-center w-[98%]'>
                <h4 className=''>Grand Total</h4>
                <h6 className=''>PKR - {grandTotal}.00</h6>
            </div>

            <div className='flex gap-2 justify-center items-center mt-5 w-[98%]'>
                {!orderData?.OrderData?.isProcessed &&
                    <button className='bg-lime-700 p-2 rounded-md text-white mb-5'
                        onClick={() => { orderStateHandler(orderData?.OrderData?._id, "revive") }}>Revive Order</button>}

                {!orderData?.OrderData?.isConfirmed && orderData?.OrderData?.isProcessed &&
                    <button className='bg-indigo-700 p-2 rounded-md text-white mb-5'
                        onClick={() => { orderStateHandler(orderData?.OrderData?._id, "confirm") }}>Confirm Order</button>}

                {!orderData?.OrderData?.isDelivered && orderData?.OrderData?.isProcessed &&
                    <button className='bg-red-700 p-2 rounded-md text-white mb-5'
                        onClick={() => { orderStateHandler(orderData?.OrderData?._id, "reject") }}>Reject Order</button>}


            </div>


        </div>
    )
}
