import React, { useEffect, useState } from 'react'
import {useSelector} from "react-redux"
import { ToastContainer, toast, Bounce } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { request } from '../../util/fetchAPI'
import { Link } from "react-router-dom"
import { MdDelete, MdEdit } from "react-icons/md";
import 'react-toastify/dist/ReactToastify.css';

export default function PaymentMethods() {

  const [state, setState] = useState()
  const [paymentData, setPaymentData] = useState([])
  const [deletingId, setDeletingId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoading2, setIsLoading2] = useState(false)
  const {token} = useSelector((state)=>state.auth)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleState = (e) => {
    setState((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  const successNotifier = (data) => {

    toast.success(data, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });

  }

  const warnNotifier = (data) => {

    toast.warn(data, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });
  }

  const errorNotifier = (data) => {

    toast.error(data, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });

  }

  //Fetch Products Catagories
  const fetechPaymentMethods = async () => {
    try {
      const paymentData = await request("/payment/getAllPeymentMethods", "GET")
      setPaymentData(paymentData)
    } catch (error) {
      console.log(error)
    }
  }


  //Register Products Catagories
  const handleRegister = async (e) => {
    e.preventDefault()
    try {
      setIsLoading(true)
      const paymentData = await request("/payment/registerPaymentMethod", "POST", {
        authorization:`Bearer ${token}`,
        "Content-Type": "application/json"
      }, { ...state })

      if (paymentData.status === 200) {
        successNotifier(paymentData.data)
      } else {
        errorNotifier(paymentData.data)
      }

      setIsLoading(false)
      fetechPaymentMethods()

    } catch (error) {
      console.log(error)
    }
  }

  //delete Products Catagories
  const handleDelete = async (id) => {
    try {
      setIsLoading2(true)
      const paymentData = await request(`/payment/deletePaymentMethod/${id}`, "DELETE", {
        authorization:`Bearer ${token}`,
        "Content-Type": "application/json"
      })
      setIsLoading2(false)
      handleClose();
      fetechPaymentMethods()

      if (paymentData.status === 200) {
        warnNotifier(paymentData.data)
      } else {
        errorNotifier(paymentData.data)
      }

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetechPaymentMethods()
  }, [])

  return (
    <div>
      <ToastContainer />
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb m-4">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Payment Methods</li>
        </ol>
      </nav>
      <div className='ProductCat' >
        <div className='ProductCatForm'>
          <fieldset className="border-2 border-black p-1">
            <legend className="font-bold text-blue-700 ">Payment Methods</legend>
            <form onSubmit={handleRegister}>

              <div className="row mobileForm">
                <div className="col">
                  <label htmlFor="" className=' block mb-2 labels'>Bank Name</label>
                  <input type="text" name='bankName' className="form-control inputs" placeholder="Enter Bank Name" onChange={handleState} required />
                </div>
                <div className="col">
                  <label htmlFor="" className=' block mb-2 labels'>Account Title</label>
                  <input type="text" name='accountTitle' className="form-control inputs" placeholder="Enter Account Title" onChange={handleState} required />
                </div>
              </div>
              <div className="row mobileForm">
                <div className="col">
                  <label htmlFor="" className=' block mb-2 labels'>Account Number/IBAN</label>
                  <input type="text" name='accountNumber' className="form-control inputs" placeholder="Enter Account Number/IBAN" onChange={handleState} required />
                </div>
                <div className="col">
                  <label htmlFor="" className=' block mb-2 labels'>Note</label>
                  <input type="text" name='Note' className="form-control inputs" placeholder="Enter Note If Any" onChange={handleState}  />
                </div>

              </div>

              <div>
                <button className='button-Primary btn btn-primary'>
                  Submit
                  {isLoading &&
                    <div className="spinner-border" style={{ height: "20px", width: "20px", marginLeft: "5px" }} role="status">
                      <span className="sr-only"></span>
                    </div>}
                </button>
              </div>
            </form>
          </fieldset>
        </div>
      </div>

      <div className='container tableContainer mt-5'>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Bank Name</th>
              <th>Account Title</th>
              <th>Account/IBAN</th>
              <th>Note</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {paymentData.length === 0
              ?
              <tr>
                <td colSpan="4">No Data</td>
              </tr>
              :
              paymentData.map((data, index) => (
                <tr key={data._id}>
                  <td>{++index}</td>
                  <td>{data.bankName}</td>
                  <td>{data.accountTitle}</td>
                  <td>{data.accountNumber}</td>
                  <td>{data.Note}</td>
                  <td>{data.isActive === true ? "Active" : "In-Active"}</td>
                  <td className='w-10'>
                    <div className='flex'>
                      <MdDelete className='DeleteIcon' onClick={() => { handleShow(); setDeletingId(data._id) }} />
                      <MdEdit className='EditIcon' />
                    </div>
                  </td>
                </tr>

              ))}
          </tbody>

        </table>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Deletion Warring!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do You Want To Delete This Data</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => { handleDelete(deletingId) }}>
            Delete
            {isLoading2 &&
              <div className="spinner-border" style={{ height: "20px", width: "20px", marginLeft: "5px" }} role="status">
                <span className="sr-only"></span>
              </div>}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

  )
}
