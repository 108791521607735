import React, { useEffect, useState } from 'react'
import {useSelector} from "react-redux"
import { MdDelete, MdEdit } from "react-icons/md";
import { request } from '../../util/fetchAPI'
import { Link } from "react-router-dom"

export default function ClientList() {
  const [clientData, setClientData] = useState([])
  const {token} = useSelector((state)=>state.auth)

  //Fetch Products Catagories
  const fetechClientData = async () => {
    try {
      const Data = await request("/user/getAllclient", "GET",
        {authorization:`Bearer ${token}`,}
      )
      setClientData(Data)
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(()=>{
    fetechClientData()
  },[])
  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb m-4">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Client</li>
        </ol>
      </nav>
      <div className='container tableContainer'>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Client Name</th>
              <th>Client Email</th>
              <th>Phone No.</th>
              <th>City</th>
              <th>State</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {clientData.map((data, index)=>(
            <tr key={data._id}>
              <td>{++index}</td>
              <td>{data.clientName}</td>
              <td>{data.clientEmail}</td>
              <td>{data.clientContact1}</td>
              <td>{data.City}</td>
              <td>{data.State}</td>
              <td>{data.isActive === true ? "Active" : "In-Active"}</td>
              <td className='w-10'>
                  <div className='flex'>
                    <MdDelete className='DeleteIcon'/*  onClick={() => { handleShow(); setDeletingId(data._id) }} */ />
                      <MdEdit className='EditIcon' />
                    </div>
                    </td>
            </tr>
            ))
            }
          </tbody>

        </table>
      </div>

    </div>
  )
}
