import React from 'react'
import { Link } from "react-router-dom"

export default function Payments() {

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb m-4">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Payment</li>
        </ol>
      </nav>
      <div className='container tableContainer'>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Product Name</th>
              <th>Category</th>
              <th>Size</th>
              <th>Material</th>
              <th>Unit</th>
              <th>Promo Price</th>
              <th>Price</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>#</td>
              <td>Key Chain</td>
              <td>Gift</td>
              <td>14"</td>
              <td>Steel</td>
              <td>No</td>
              <td>12000</td>
              <td>10000</td>
            </tr>
          </tbody>

        </table>
      </div>

    </div>
  )
}
