import React, { useState } from 'react'
import {useSelector} from "react-redux"
import { Link } from "react-router-dom"
import { request } from '../../util/fetchAPI'


export default function CompanyProfileForm() {
    const [state, setState] = useState()
    const [photo, setPhoto] = useState();
    const {token} = useSelector((state)=>state.auth)
    const [isLoading, setIsLoading] = useState(false)

    const handleState = (e) => {
        setState((prev) => {
            return { ...prev, [e.target.name]: e.target.value }
        })
    }

    const handleRegister = async (e) => {
        e.preventDefault()
        try {
            setIsLoading(true)
            let filename = null
            const formData = new FormData();
            if (photo) {
                filename = crypto.randomUUID() + photo.name;
                formData.append("filename", filename);
                formData.append("image", photo);
                await fetch(`https://api.ishaqkhantruckart.com/upload/image`, {
                    headers: {
                      authorization: `Bearer ${token}`
                    },
                    method: "POST",
                    body: formData
                  });
              }


            await request("/company/registerCompany", "POST", {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }, { ...state,  companyLogo:filename})
            setIsLoading(true)
            window.location.reload()

        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-4">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Company Registration</li>
                </ol>
            </nav>
            <div className='ProductCat' >
                <div className='ProductCatForm'>
                    <fieldset className="border-2 border-black p-1">
                        <legend className="font-bold text-blue-700 ">Company Profile</legend>
                        <form onSubmit={handleRegister}>

                            <div className="row mobileForm">
                                <div className="col">
                                    <label htmlFor="" className=' block mb-2 labels'>Company Name</label>
                                    <input type="text" name='companyName' className="form-control inputs" placeholder="Enter Proprietor/Owner Name" onChange={handleState} />
                                </div>
                                <div className="col">
                                    <label htmlFor="" className=' block mb-2 labels'>Proprietor/Owner Name</label>
                                    <input type="text" name='companyProprietor' className="form-control inputs" placeholder="Enter Proprietor/Owner Name" onChange={handleState} />
                                </div>
                            </div>

                            <div className="row mobileForm">
                                <div className="col">
                                    <label htmlFor="" className=' block mb-2 labels'>Contact Number</label>
                                    <input type="text" name='companyContact' className="form-control inputs" placeholder="Enter Contact Number" onChange={handleState} />
                                </div>
                                <div className="col">
                                    <label htmlFor="" className=' block mb-2 labels'>Email</label>
                                    <input type="text" name='companyEmail' className="form-control inputs" placeholder="example@xyz.com" onChange={handleState} />
                                </div>
                            </div>

                            <div className="row mobileForm">
                                <div className="col">
                                    <label htmlFor="" className=' block mb-2 labels'>FaceBook</label>
                                    <input type="text" name='companyFaceBook' className="form-control inputs" placeholder="Facebook Link" onChange={handleState} />
                                </div>
                                <div className="col">
                                    <label htmlFor="" className=' block mb-2 labels'>Instagram</label>
                                    <input type="text" name='companyInsta' className="form-control inputs" placeholder="Instagram Link" onChange={handleState} />
                                </div>
                            </div>

                            <div className="row mobileForm">

                                <div className="col">
                                    <label htmlFor="" className=' block mb-2 labels'>Address</label>
                                    <textarea type="text" min='1' name='companyAddress' className="form-control inputs" placeholder="Enter Address" onChange={handleState} />
                                </div>
                            </div>

                            <div className="row mobileForm">
                                <div className="col">
                                    <label htmlFor="" className=' block mb-2 labels'>Company Logo</label>
                                    <input className="form-control inputs" name="photo"
                                     type="file" id="photo" onChange={(e) => setPhoto(e.target.files[0])} required />
                                </div>

                            </div>
                            <div>
                                <button className='button-Primary btn btn-primary'>
                                    Submit
                                    {isLoading &&
                                        <div className="spinner-border" style={{ height: "20px", width: "20px", marginLeft: "5px" }} role="status">
                                            <span className="sr-only"></span>
                                        </div>}
                                </button>
                            </div>
                        </form>
                    </fieldset>
                </div>
            </div>
        </div>

    )
}
