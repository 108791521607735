import React, { useState } from 'react'
import {useSelector} from "react-redux"
import { MdDelete, MdEdit } from "react-icons/md";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { request } from '../../util/fetchAPI';
import Logo from "../../assets/Logo.jpg"
import { Link } from "react-router-dom"
import Preloader from '../Preloader/Preloader';


export default function CompanyProfile({ companyData }) {
    const [deletingId, setDeletingId] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const {token} = useSelector((state)=>state.auth)

         //delete Products
         const handleDelete = async (id) => {
            try {
              setIsLoading(true)
                await request(`/company/deleteCompany/${id}`, "DELETE", {
                authorization:`Bearer ${token}`,
                "Content-Type": "application/json"
              })
              setIsLoading(false)
              handleClose();
              window.location.reload()

            } catch (error) {
              console.log(error)
            }
          }


    return (
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-4">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Company Profile</li>
                </ol>
            </nav>

            <div>
                <div className='ProductCat' >
                    <div className='ProductCatForm'>
                        <fieldset className="border-2 border-black p-1">
                            <legend className="font-bold text-blue-700 ">Company Profile</legend>

                            <div className="row mobileForm">
                                <div className="col">
                                    <img src={companyData.companyLogo ? `https://api.ishaqkhantruckart.com/images/${companyData.companyLogo}` : "NULL"} className='h-75 w-25' />
                                </div>
                            </div>
                            <div className="row mobileForm">
                                <div className="col">
                                    <label htmlFor="" className=' block labels'>Company Name</label>
                                    <h6 className='m-2'>{companyData.companyName}</h6>
                                </div>
                                <div className="col">
                                    <label htmlFor="" className=' block labels'>Proprietor/Owner Name</label>
                                    <h6 className='m-2'>{companyData.companyProprietor}</h6>
                                </div>
                            </div>

                            <div className="row mobileForm">
                                <div className="col">
                                    <label htmlFor="" className=' block labels'>Contact Number</label>
                                    <h6 className='m-2'>{companyData.companyContact}</h6>
                                </div>
                                <div className="col">
                                    <label htmlFor="" className=' block labels'>Email</label>
                                    <h6 className='m-2'>{companyData.companyEmail}</h6>
                                </div>
                            </div>

                            <div className="row mobileForm">
                                <div className="col">
                                    <label htmlFor="" className=' block labels'>FaceBook</label>
                                    <h6 className='m-2'>{companyData.companyFaceBook}</h6>
                                </div>
                                <div className="col">
                                    <label htmlFor="" className=' block labels'>Instagram</label>
                                    <h6 className='m-2'>{companyData.companyInsta}</h6>
                                </div>
                            </div>

                            <div className="row mobileForm">

                                <div className="col">
                                    <label htmlFor="" className=' block labels'>Address</label>
                                    <h6 className='m-2'>{companyData.companyAddress}</h6>
                                </div>
                            </div>

                        </fieldset>
                    </div>
                </div>
                <hr />
                <div className='m-5 flex gap-1'>
                <MdDelete className='DeleteIcon bg-red-500 rounded-lg'  onClick={() => { handleShow(); setDeletingId(companyData._id) }}  /><MdEdit className='EditIcon bg-lime-500 rounded-lg'  />
                </div>

            </div>
            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Deletion Warring!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do You Want To Delete This Data</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => { handleDelete(deletingId) }}>
            Delete
            {isLoading &&
              <div className="spinner-border" style={{ height: "20px", width: "20px", marginLeft: "5px" }} role="status">
                <span className="sr-only"></span>
              </div>}
          </Button>
        </Modal.Footer>
      </Modal>
            </div>
    )
}
