import React, { useEffect, useState } from 'react'
import {useSelector} from "react-redux"
import { ToastContainer, toast, Bounce } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { request } from '../../util/fetchAPI'
import { Link } from "react-router-dom"
import { MdDelete, MdEdit } from "react-icons/md";
import 'react-toastify/dist/ReactToastify.css';
import "./ProductsList.css"

export default function ProductsList() {

  const [productData, setProductData] = useState([])
  const [deletingId, setDeletingId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const {token} = useSelector((state)=>state.auth)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


const warnNotifier = (data)=>{

  toast.warn(data, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    transition: Bounce,
    });
}

const errorNotifier = (data)=>{

  toast.error(data, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    transition: Bounce,
    });

}

  //Fetch Products Catagories
  const fetechProducts = async () => {
    try {
      const productData = await request("/product/getAllProducts", "GET")
      setProductData(productData)
    } catch (error) {
      console.log(error)
    }
  }

   //delete Products
   const handleDelete = async (id) => {
    try {
      setIsLoading(true)
      const productData = await request(`/product/deleteProduct/${id}`, "DELETE", {
        authorization:`Bearer ${token}`,
        "Content-Type": "application/json"
      })
      setIsLoading(false)
      handleClose();
      fetechProducts()
      if(productData.status === 200){
        warnNotifier(productData.data)
      }else{
        errorNotifier(productData.data)
      }

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetechProducts()
  }, [])

  return (
    <div>
      <ToastContainer />
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb m-4">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Product List</li>
        </ol>
      </nav>
      <div className='container tableContainer'>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Product Name</th>
              <th>Category</th>
              <th>Size</th>
              <th>Material</th>
              <th>Unit</th>
              <th>Promo Price</th>
              <th>Price</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {productData.length === 0
              ?
              <tr>
                <td colSpan="4">No Data</td>
              </tr>
              :
              productData.map((data, index) => (
                <tr key={data._id}>
                  <td>{++index}</td>
                  <td>{data.productName}</td>
                  <td>{data.productCat?.proCatDec}</td>
                  <td>{data.productSize}</td>
                  <td>{data.productMaterial}</td>
                  <td>{data.productUnit}</td>
                  <td>{data.promoPrice}</td>
                  <td>{data.productPrice}</td>
                  <td className='w-10'>
                  <div className='flex'>
                    <MdDelete className='DeleteIcon' onClick={() => { handleShow(); setDeletingId(data._id) }} />
                      <MdEdit className='EditIcon' />
                    </div>
                    </td>
                </tr>

              ))}
          </tbody>

        </table>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Deletion Warring!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do You Want To Delete This Data</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => { handleDelete(deletingId) }}>
            Delete
            {isLoading &&
              <div className="spinner-border" style={{ height: "20px", width: "20px", marginLeft: "5px" }} role="status">
                <span className="sr-only"></span>
              </div>}
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}
