import React from 'react'
import { Link } from "react-router-dom"
import "./DashBoard.css"

export default function DashBoard() {
  return (
    <>
        <nav aria-label="breadcrumb">
  <ol className="breadcrumb m-4">
    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
  </ol>
</nav>
<div className='DashBoradContainer'>
   
      <div className='dashCard'>
        <span className='DescripionSpan'>
        Total Products
        </span>
        
        <span className='ValueSpan'>
          30
        </span>

      </div>
      <div className='dashCard'>
      <span className='DescripionSpan'>Total Order</span>
      <span className='ValueSpan'>20</span>
        </div>

      <div className='dashCard'>
      <span className='DescripionSpan'>Orders In-Que</span>
      <span className='ValueSpan'>0</span>
        </div>

      <div className='dashCard'>
      <span className='DescripionSpan'>Orders Processed</span>
      <span className='ValueSpan'>2</span>
      </div>

      <div className='dashCard'>
      <span className='DescripionSpan'>Orders Return</span>
      <span className='ValueSpan'>0</span>
        </div>

      <div className='dashCard'>
      <span className='DescripionSpan'>Payment Recevied</span>
      <span className='ValueSpan'>15000/-</span>
        </div>

      <div className='dashCard'>
      <span className='DescripionSpan'>Total Profit</span>
      <span className='ValueSpan'>15000/-</span> 
        </div>

      <div className='dashCard'>
      <span className='DescripionSpan'>Total Users</span>
      <span className='ValueSpan'>150</span> 
        </div>
    </div>
</>
    
  )
}
