import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [], // Array to store cart items
  totalQuantity: 0,
  totalPrice: 0,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItemToCart(state, action) {
      const newItem = action.payload;
      const existingItem = state.items.find(item => item.id === newItem._id);

      if (!existingItem) {
        state.items.push({
          id: newItem.product?.product?._id,
          productName: newItem.product?.product?.productName,
          productCat: newItem.product?.product?.productCat?.proCatDec,
          productUnit: newItem.product?.product?.productUnit,
          promoPrice: newItem.product?.product?.promoPrice,
          productPrice: newItem.product?.product?.productPrice,
          quantity: newItem.product?.productQty,
          totalPrice: (newItem.product?.promoPrice || newItem.product?.productPrice) * newItem.productQty,
        });
        state.totalQuantity++;
      } else {
        existingItem.quantity += newItem.productQty;
        existingItem.totalPrice = (existingItem.promoPrice || existingItem.productPrice) * existingItem.quantity;
      }

      state.totalPrice = state.items.reduce((total, item) => total + item.totalPrice, 0);
    },
    removeItemFromCart(state, action) {
      const id = action.payload;
      const existingItem = state.items.find(item => item.id === id);

      if (existingItem) {
        state.totalQuantity--;
        state.totalPrice -= existingItem.totalPrice;
        state.items = state.items.filter(item => item.id !== id);
      }
    },
    clearCart(state) {
      state.items = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;
    },
  },
});

export const { addItemToCart, removeItemFromCart, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
