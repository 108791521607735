import React, { useEffect, useState } from 'react'
import {useSelector} from "react-redux"
import { ToastContainer, toast, Bounce } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MdDelete, MdEdit } from "react-icons/md";
import { request } from '../../util/fetchAPI';
import { Link } from "react-router-dom"
import "./UserList.css"

export default function UserList() {

  const [userData, setUserData] = useState([])
  const [deletingId, setDeletingId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const {token} = useSelector((state)=>state.auth)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const warnNotifier = (data)=>{

    toast.warn(data, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
      });
  }

  const errorNotifier = (data)=>{

    toast.error(data, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
      });

  }

const fetchUsers = async()=>{

    try {
      const Data = await request("/user/getAllusers", "GET", {
        authorization:`Bearer ${token}`,
      })
      setUserData(Data)

    } catch (error) {
          console.log(error)
    }

  }

     //delete Products
     const handleDelete = async (id) => {
      try {
        setIsLoading(true)
        const UserData = await request(`/user/deleteUsers/${id}`, "DELETE", {
          authorization:`Bearer ${token}`,
          "Content-Type": "application/json"
        })
        setIsLoading(false)
        handleClose();
        fetchUsers()
        if(UserData.status === 200){
          warnNotifier(UserData.data)
        }else{
          errorNotifier(UserData.data)
        }

      } catch (error) {
        console.log(error)
      }
    }

  useEffect(()=>{
    fetchUsers()
  },[])

  return (
    <div>
           <ToastContainer />
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb m-4">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">User List</li>
        </ol>
      </nav>
      <div className='container tableContainer'>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>User Name</th>
              <th>Email</th>
              <th>Phone No.</th>
              <th>Gender</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>

            {userData.map((data, index)=>(
                <tr key={data._id}>
                <td>{++index}</td>
                <td>{data.userName}</td>
                <td>{data.userEmail}</td>
                <td>{data.phonNumber}</td>
                <td>{data.gender}</td>
                <td>{data.isActive === true ? "ACTIVE" : "In-Active"}</td>
                <td className='w-10'>
                  <div className='flex'>
                    <MdDelete className='DeleteIcon' onClick={() => { handleShow(); setDeletingId(data._id) }} />
                      <MdEdit className='EditIcon' />
                    </div>
                    </td>
                </tr>
            ))}

          </tbody>

        </table>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Deletion Warring!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do You Want To Delete This Data</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => { handleDelete(deletingId) }}>
            Delete
            {isLoading &&
              <div className="spinner-border" style={{ height: "20px", width: "20px", marginLeft: "5px" }} role="status">
                <span className="sr-only"></span>
              </div>}
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}
