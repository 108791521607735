import React, { useEffect, useState } from 'react'
import {useSelector} from "react-redux"
import { request } from '../../util/fetchAPI';
import CompanyProfileForm from './CompanyProfileForm'
import CompanyProfile from './CompanyProfile'
import Preloader from '../Preloader/Preloader';

export default function General() {
  const [companyData , setCompanyData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const {token} = useSelector((state)=>state.auth)
  const fetchProfile = async()=>{
    try {

      const Data = await request("/company/getCompany", "GET",{
        authorization:`Bearer ${token}`,
      })
      setCompanyData(Data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    fetchProfile()
  },[])
  return (
    <div>
      {isLoading ?
      <Preloader/>
      :
      companyData ?
      <CompanyProfile companyData= {companyData}/>
      :
        <CompanyProfileForm />
      }
    </div>
  )
}
