import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Order from '../Orders/Order'
import ClearOrders from '../ClearOrders/ClearOrders'
import ProductsList from '../ProductsList/ProductsList'
import ProductCat from '../ProductCat/ProductCat'
import AddProducts from '../AddProducts/AddProducts'
import DashBoard from '../DashBoard/DashBoard'
import PaymentMethods from '../PaymentMethods/PaymentMethods'
import Payments from '../Payments/Payments'
import UserList from '../UserList/UserList'
import General from '../General/General'
import ClientList from '../ClientList/ClientList'
import OrderPreview from '../OrderPreview/OrderPreview'
import ProductReview from '../ProductReview/ProductReview'

export default function AppRoutes() {

  return (
    <div>
    <Routes>
    <Route path='/' element={<DashBoard/>}/>
    <Route path='/general' element={<General/>}/>
    <Route path='/productsCat' element={<ProductCat/>}/>
    <Route path='/addProducts' element={<AddProducts/>}/>
    <Route path='/productsList' element={<ProductsList/>}/>
    <Route path='/orders' element={<Order/>}/>
    <Route path='/orderPreview/:id' element={<OrderPreview/>}/>
    <Route path='/clearOrders' element={<ClearOrders/>}/>
    <Route path='/addPaymentMethods' element={<PaymentMethods/>}/>
    <Route path='/payments' element={<Payments/>}/>
    <Route path='/userList' element={<UserList/>}/>
    <Route path='/clients' element={<ClientList/>}/>
    <Route path='/ProductReviews' element={<ProductReview/>}/>
    </Routes>
    </div>
  )
}
